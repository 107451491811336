import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { tap } from 'rxjs';
import { ENavigationRoute } from '../model/navigation.model';
import { AccountDataService } from '../services/auth/account-data.service';
import { RefreshTokenService } from '../services/auth/refresh-token/refresh-token.service';

export const authGuard: CanActivateFn = () => {
  const router = inject(Router);
  if (inject(RefreshTokenService).hasToken) {
    return inject(AccountDataService)
      .checkIfUserIsAuthorized$()
      .pipe(
        tap((isLoggedIn) => {
          if (!isLoggedIn) {
            router.navigate([ENavigationRoute.LOGIN]);
          }
        })
      );
  } else {
    return false;
  }
};
