import { ErrorComponent } from './error.component';
import { ErrorRouteData } from './error-component.model';
import { IShrlRoutes } from '../../../shrl.routes';

export const ERROR_ROUTES: IShrlRoutes = [
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'access-denied',
    component: ErrorComponent,
    data: {
      statusCode: '403',
    } as ErrorRouteData,
  },
];
