import { authGuard } from '../../core/guards/auth.guard';
import {
  ENavigationUnitComponentSelection,
  ENavigationRoute,
} from '../../core/model/navigation.model';
import { EUserRole } from '../../core/model/user.model';
import { IShrlRoutes } from '../../shrl.routes';

export const SUPPORT_ROUTES: IShrlRoutes = [
  {
    path: ENavigationRoute.SUPPORT,
    loadComponent: () =>
      import('./pages/support-faqs/support-faqs.component').then(
        (x) => x.FaqsComponent
      ),
    canActivate: [authGuard],
    data: {
      componentSelection: [
        ENavigationUnitComponentSelection.SIDENAV,
        ENavigationUnitComponentSelection.TOPBAR,
        ENavigationUnitComponentSelection.FOOTER,
      ],
      iconName: 'info_outlined',
      visibleForUsers: [EUserRole.ROLE_USER],
      label: 'SUPPORT.LABEL',
    },
  },
];
