import { ENavigationRoute } from '../../core/model/navigation.model';
import { IShrlRoutes } from '../../shrl.routes';

export const GUEST_ROUTE: IShrlRoutes = [
  {
    path: ENavigationRoute.GUEST,
    loadComponent: () =>
      import('./pages/guest-form/guest-form.component').then(
        (x) => x.GuestFormComponent
      ),
  },
];
