import { IAccountBase } from '../../features/account/model/account.model';
import { ICompanyDetailsDTO } from '../../features/account/model/company-details.model';

export interface IUserDTO extends IAccountBase {
  login: string;
  imageUrl: string;
  activated: boolean;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  authorities: EUserRole[];
  companyId: number;
}

export interface IUserWithCompanyDTO extends IUserDTO {
  company?: ICompanyDetailsDTO;
}

export enum EUserRole {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_INSTALLER = 'ROLE_INSTALLER',
  ROLE_USER = 'ROLE_USER',
}
