import {
  ENavigationUnitComponentSelection,
  ENavigationRoute,
} from '../../core/model/navigation.model';
import { IShrlRoutes } from '../../shrl.routes';

export const HOME_ROUTES: IShrlRoutes = [
  {
    path: ENavigationRoute.ABOUT_US,
    loadComponent: () =>
      import('./pages/about-us/about-us.component').then(
        (x) => x.AboutUsComponent
      ),
    data: {
      label: 'ABOUT_US.LABEL',
      componentSelection: [
        ENavigationUnitComponentSelection.SIDENAV,
        ENavigationUnitComponentSelection.TOPBAR,
        ENavigationUnitComponentSelection.FOOTER,
      ],
    },
  },
  {
    path: ENavigationRoute.HOW_IT_WORKS,
    loadComponent: () =>
      import('./pages/how-it-works/how-it-works.component').then(
        (x) => x.HowItWorksComponent
      ),
    data: {
      label: 'HOW_IT_WORKS.LABEL',
      componentSelection: [],
    },
  },
  {
    path: 'faqs',
    loadComponent: () =>
      import('./pages/faqs/faqs.component').then((x) => x.FAQsComponent),
    data: {
      label: 'FAQS.LABEL',
      componentSelection: [
        ENavigationUnitComponentSelection.SIDENAV,
        ENavigationUnitComponentSelection.TOPBAR,
        ENavigationUnitComponentSelection.FOOTER,
      ],
    },
  },
  {
    path: ENavigationRoute.IMPRINT,
    loadComponent: () =>
      import('./pages/imprint/imprint.component').then(
        (x) => x.ImprintComponent
      ),
    data: {
      label: 'IMPRINT.LABEL',
      componentSelection: [ENavigationUnitComponentSelection.FOOTER],
    },
  },
  {
    path: ENavigationRoute.CONTACT,
    loadComponent: () =>
      import('./pages/contact/contact.component').then(
        (x) => x.ContactComponent
      ),
    data: {
      label: 'CONTACT.LABEL',
      componentSelection: [
        ENavigationUnitComponentSelection.SIDENAV,
        ENavigationUnitComponentSelection.TOPBAR,
        ENavigationUnitComponentSelection.FOOTER,
      ],
    },
  },
  {
    path: ENavigationRoute.REGULATIONS,
    loadComponent: () =>
      import('./pages/regulations/regulations.component').then(
        (x) => x.RegulationsComponent
      ),
    data: {
      componentSelection: [],
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ENavigationRoute.PRIVACY_POLICY,
      },
      {
        path: ENavigationRoute.PRIVACY_POLICY,
        loadComponent: () =>
          import(
            './pages/regulations/regulation-file/privacy-policy/privacy-policy.component'
          ).then((x) => x.PrivacyPolicyComponent),
        data: {
          label: 'SHARED.GENERAL.PRIVACY_POLICY',
          componentSelection: [ENavigationUnitComponentSelection.FOOTER],
        },
      },
      {
        path: ENavigationRoute.TERMS,
        loadComponent: () =>
          import(
            './pages/regulations/regulation-file/terms/terms.component'
          ).then((x) => x.TermsComponent),
        data: {
          label: 'SHARED.GENERAL.TERMS',
          componentSelection: [ENavigationUnitComponentSelection.FOOTER],
        },
      },
    ],
  },
];
