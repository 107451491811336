import { authGuard } from '../../core/guards/auth.guard';
import {
  ENavigationRoute,
  ENavigationUnitComponentSelection,
} from '../../core/model/navigation.model';
import { EUserRole } from '../../core/model/user.model';
import { IShrlRoutes } from '../../shrl.routes';

export const REQUEST_ROUTES: IShrlRoutes = [
  {
    path: ENavigationRoute.REQUESTS,
    canActivate: [authGuard], // add installer role
    loadComponent: () =>
      import('./pages/requests-list/requests-list.component').then(
        (x) => x.RequestsListComponent
      ),
    data: {
      label: 'REQUEST.HEADING',
      componentSelection: [
        ENavigationUnitComponentSelection.TOPBAR,
        ENavigationUnitComponentSelection.SIDENAV,
      ],
      visibleForUsers: [EUserRole.ROLE_INSTALLER, EUserRole.ROLE_ADMIN], // admin for testing
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: ENavigationRoute.PENDING,
      },
      {
        path: ENavigationRoute.PENDING,
        loadComponent: () =>
          import(
            './pages/requests-list/tab/requests-tab-pending/requests-tab-pending.component'
          ).then((x) => x.RequestsTabPendingComponent),
        data: {
          label: 'REQUEST.LIST.STATE.PENDING',
          componentSelection: [
            ENavigationUnitComponentSelection.TOPBAR,
            ENavigationUnitComponentSelection.SIDENAV,
          ],
        },
      },
      {
        path: ENavigationRoute.ACCEPTED,
        loadComponent: () =>
          import(
            './pages/requests-list/tab/requests-tab-accepted/requests-tab-accepted.component'
          ).then((x) => x.RequestsTabAcceptedComponent),
        data: {
          label: 'REQUEST.LIST.STATE.ACCEPTED',
          componentSelection: [
            ENavigationUnitComponentSelection.TOPBAR,
            ENavigationUnitComponentSelection.SIDENAV,
          ],
        },
      },
      {
        path: ENavigationRoute.CONFIGURED,
        loadComponent: () =>
          import(
            './pages/requests-list/tab/requests-tab-configured/requests-tab-configured.component'
          ).then((x) => x.RequestsTabConfiguredComponent),
        data: {
          label: 'REQUEST.LIST.STATE.CONFIGURED',
          componentSelection: [
            ENavigationUnitComponentSelection.TOPBAR,
            ENavigationUnitComponentSelection.SIDENAV,
          ],
        },
      },
    ],
  },
  // ROUTING TODO
  {
    path: `${ENavigationRoute.REQUESTS}/${ENavigationRoute.NEW}`,
    // canActivate: [authGuard], // add installer role
    loadComponent: () =>
      import('./pages/installation/installation.component').then(
        (x) => x.InstallationComponent
      ),
    data: {
      label: 'REQUEST.CREATE.LABEL',
      componentSelection: [ENavigationUnitComponentSelection.SIDENAV],
      // visibleForUsers: [EUserRole.ROLE_INSTALLER, EUserRole.ROLE_ADMIN], // admin for testing
    },
  },
];
