import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ErrorRouteData } from './error-component.model';
import { SharelockCommonModule } from '../../modules/sharelock-common.module';

@Component({
  selector: 'shrl-error-component',
  standalone: true,
  imports: [SharelockCommonModule],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent {
  routeData$: Observable<ErrorRouteData>;

  constructor(private readonly route: ActivatedRoute) {
    this.routeData$ = route.data as Observable<ErrorRouteData>;
  }
}
