import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';
import { AccountDataService } from '../../../core/services/auth/account-data.service';
import { EUserRole } from '../../../core/model/user.model';
import { ENavigationRoute } from '../../../core/model/navigation.model';

export const adminGuard: CanActivateFn = () => {
  const router = inject(Router);
  return inject(AccountDataService).userRoles$.pipe(
    map((userRoles) => {
      const hasAdminRole = !!userRoles?.includes(EUserRole.ROLE_ADMIN);
      if (!hasAdminRole) {
        router.navigate([ENavigationRoute.PROPERTIES]);
      }
      return hasAdminRole;
    })
  );
};
