import { inject } from '@angular/core';
import { ResolveFn, ActivatedRouteSnapshot } from '@angular/router';
import { map, of } from 'rxjs';
import { ApartmentApiService } from './apartment-api.service';

export const ApartmentBreadcrumbResolver: ResolveFn<string | undefined> = (
  route: ActivatedRouteSnapshot
) => {
  const apartmentId = Number(route.paramMap.get('id'));
  if (apartmentId == null || isNaN(apartmentId)) {
    return of(undefined);
  }
  return apartmentId
    ? inject(ApartmentApiService)
        .getApartment(apartmentId, 3)
        .pipe(map((apartment) => apartment.name))
    : of(undefined);
};
